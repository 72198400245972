<template>
  <section >
    <h2 class="titulo-links-escolha">EDITAR DISCIPLINA</h2>
    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6 lg:col-3">
            <label>Nome da Disciplina</label>
            <pm-InputText  v-model="disciplinaEscolar.nome"  />
          </div>
          <div class="field col-12 md:col-6 lg:col-3">
            <label>Carga Horária</label>
            <pm-InputText :style="errorColor" type="number" @input="verificarCh" @change="verificarCh" v-model="disciplinaEscolar.carga_horaria" />
            <small v-if="notInt" v-show="notInt" class="p-error">DIGITE APENAS NÚMEROS INTEIROS. </small>
          </div>
          <div class="field col-12 md:col-3" style="padding-top: 16px;">
            <div style="margin-top:15px">
              <label>A disciplina está ativa? <pm-InputSwitch v-model="status" @click="mudarStatus" style="vertical-align:middle !important"/> </label>
            </div>
          </div>

        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />

          <pm-Button   class="p-button-success"  @click="editarDisciplina" v-if="disciplinaEscolar.nome
                                                                                && disciplinaEscolar.nome.length > 3
                                                                                && verificarCh() == true"
             label="Editar"
             icon="pi pi-check" iconPos="right"
           />
        </div>
      </template>
    </pm-Card>

  </section >
</template>

<script>

import { DisciplinaEscolar } from "@/class/disciplina.js";
import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  props: {
    id:{
    },
  },
  components: {

  },
  name: 'Disciplina-Escolar',
  data () {
    return {
      disciplinaEscolar: {
        id: null,
        nome: null,
        carga_horaria: null,
        user_id: store_token_info.usuario_logado,
      },
      status:'',

    }
  },
  methods: {

    voltar(){
      this.$router.push({name: 'coordenacao-disciplina'});
    },

    async editarOption(id){
        try {
          let data = await DisciplinaEscolar.obtemUm(id);
          this.disciplinaEscolar.id = data.data.id;
          this.disciplinaEscolar.nome = data.data.nome;
          this.disciplinaEscolar.carga_horaria = data.data.carga_horaria;
          this.status = (data.data.status == 1 ? true : false);

        } catch (e) {
          // console.log(e);
      }
    },

    async mudarStatus(){
      try {
        let payload = {
          id:  this.disciplinaEscolar.id,
          status: (this.status ? 0 : 1),
        };
        let data = await DisciplinaEscolar.alterarStatus(payload);
      } catch (e) {

      }
    },
    //verifica se a carga horária é um int
    verificarCh(){
      if(this.disciplinaEscolar.carga_horaria % 1 == 0){
        this.notInt = false
        this.errorColor = ""
        return true;
      }else{
        this.notInt = true;
        this.errorColor = "border-color: #f44336";
        return false;
      }
    },

    async editarDisciplina() {
      try {
        if (this.disciplinaEscolar.carga_horaria === null) {
          this.disciplinaEscolar.carga_horaria = 20;
        }
        const data = await DisciplinaEscolar.alterar(this.disciplinaEscolar);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
      catch(e) {
        this.$vaToast.init({
          message: "Erro ao editar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
      this.$router.push({name: 'coordenacao-disciplina'});
    },
  },
  mounted() {
    if (this.id == null) {
      this.$router.push({name: 'coordenacao-disciplina'});
    }
    else {
      this.editarOption(this.id);
    }
  }
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
